import "./MetricPanel.css"
import ReadinessMetricCard from "./cards/ReadinessMetricCard"
import DeadlinedMetricCard from "./cards/DeadlinedMetricCard"
import SummaryMetricCard from "./cards/SummaryMetricCard"
import VehicleOverviewCard from './cards/VehicleOverviewCard'
import DowntimeMetricCard from "./cards/DowntimeMetricCard"

const MetricPanel = () => {
  
  return (
    <div className="metric-panel">
      
      <SummaryMetricCard deadlinedPercent={13} opDegradedPercent={11} opMinorPercent={42} opNoSrPercent={35}
          deadlinedCount={9} opDegradedCount={8} opMinorCount={30} opNoSrCount={25} />

      <ReadinessMetricCard readinessPercent={70} deadlinedPercent={30} />
      <DeadlinedMetricCard readinessPercent={70} deadlinedPercent={30} deadlinedCount={661}  />
      <DowntimeMetricCard downtimeDays={57} />
      <VehicleOverviewCard  overdueCount={144} activeFaultsCount={1543} warningsCount={781}  />
    </div>
  )
}

export default MetricPanel
