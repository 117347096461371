import "./DeadlinedMetricCard.css"
import { PieChart } from '../charts/PieCharTwoLabels'


const DeadlinedMetricCard = ({ readinessPercent, deadlinedPercent, deadlinedCount }: 
  { readinessPercent: number; deadlinedPercent: number; deadlinedCount: number; }) => {

  return (
    <div className="deadlined-metric-card">
      <p className="title">Deadlined Vehicles</p>
      <div className="content">
        <div>
          <PieChart 
            value1={readinessPercent} 
            value2={deadlinedPercent} 
            status={'bad'}
            width={75}
            height={75} />
          <div className="percent">30%</div>
        </div>
        <div className="value">{deadlinedCount}</div>
      </div>
      <div className="action"></div>
    </div>
  )
}

export default DeadlinedMetricCard
