import './TopFilterPanel.css'
import { useContext } from 'react'
import AppContext from '../../context/AppContext'

const TopFilterPanel = ({ 
  selectedCommandLevel, setSelectedCommandLevel }: { selectedCommandLevel: string, 
  setSelectedCommandLevel: (value: string) => void }) => {
    
  const { commandData } = useContext(AppContext)

  function handleCommandLevelChange(e: React.ChangeEvent<HTMLSelectElement>) {
    if (e.target.value === '') return; // Return early if the value is empty
    setSelectedCommandLevel(e.target.value)
  }

  return (
    <div className="top-filter-container">
      <div className="filters-column">
        <select value={selectedCommandLevel} onChange={handleCommandLevelChange} style={{ width: '320px' }}>
          {/* <option value="">- Select a Command -</option> */}
          {commandData.map((command, index) => (
            <option 
              value={command.id} 
              key={index}>
                {command.level}
            </option>
          ))}
        </select>

        <select style={{ width: '320px' }}>
          <option value="">- Select a Company -</option>
          <option value="">-</option>
          <option value="">-</option>
        </select>
{/* 
        <select>
          <option value="">Component</option>
          <option value="">-</option>
          <option value="">-</option>
        </select>

        <select>
          <option value="">Regiment/Group</option>
          <option value="">-</option>
          <option value="">-</option>
        </select>
        
        <select>
          <option value="">Unit</option>
          <option value="">-</option>
          <option value="">-</option>
        </select>

        <select>
          <option value="">TAMCN</option>
          <option value="">-</option>
          <option value="">-</option>
        </select>

        <select>
          <option value="">Individual Vehicle</option>
          <option value="">-</option>
          <option value="">-</option>
        </select> */}
      </div>
      
      {/* <div className="button-column">
        <button>Export</button>
      </div> */}
    </div>
  )
}

export default TopFilterPanel
