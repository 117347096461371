import "./SummaryMetricCard.css"
import Plot from 'react-plotly.js'
import { Layout, Data } from 'plotly.js';

type PieChartProps = {
  deadlinedPercent: number;
  opDegradedPercent: number;
  opMinorPercent: number;
  opNoSrPercent: number;
}

const SummaryMetricCard = ({ deadlinedPercent, opDegradedPercent, opMinorPercent, opNoSrPercent, 
  deadlinedCount, opDegradedCount, opMinorCount, opNoSrCount }: 
  { deadlinedPercent: number; opDegradedPercent: number;  opMinorPercent: number;  opNoSrPercent: number; 
    deadlinedCount: number; opDegradedCount: number;  opMinorCount: number;  opNoSrCount: number; 
  }) => {

  const PieChart: React.FC<PieChartProps> = ({ deadlinedPercent, opDegradedPercent, opMinorPercent, opNoSrPercent }) => {
    const chartDataArray: Data[] = [{
      type: 'pie',
      labels: ['Deadlined', 'OP-Degraded', 'OP-Minor', 'OP-NO SR'],
      values: [deadlinedPercent, opDegradedPercent, opMinorPercent, opNoSrPercent],
      showlegend: false,
      marker: {
        colors: ['#76252F', '#968917', '#246488', '#137229']  // First value will be green, second will be darker green
      },
      textinfo: 'none'
    }]

    const layout: Partial<Layout> = {
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
      showlegend: false,
      margin: { t: 0, b: 0, l: 0, r: 0 },
      width: 120,
      height: 120,
    }

    return (
      <Plot
        data={chartDataArray}
        layout={layout}
        config={{
          displayModeBar: false,
          staticPlot: true,
          responsive: true,
        }}
      />
    )
  }

  return (
    <div className="summary-metric-card">
      <p className="title">Readiness Summary</p>
      <div className="content">
        <div className="pie-chart">
          <PieChart deadlinedPercent={deadlinedPercent} opDegradedPercent={opDegradedPercent} 
            opMinorPercent={opMinorPercent} opNoSrPercent={opNoSrPercent} />
        </div>
        <div>
          <div>
            <span className="chip chip-bg-red">{deadlinedCount}</span>
            <span className="label">Deadlined</span>
            &nbsp; {deadlinedPercent}%
          </div>
          <br/>
          <div>
            <span className="chip chip-bg-yellow">{opDegradedCount}</span>
            <span className="label">Op-Degraded</span>
            &nbsp; {opDegradedPercent}%
          </div>
          <br/>
          <div>
            <span className="chip chip-bg-blue">{opMinorCount}</span>
            <span className="label">OP-Minor</span>
            &nbsp; {opMinorPercent}%
          </div>
          <br/>
          <div>
            <span className="chip chip-bg-green">{opNoSrCount}</span>
            <span className="label">OP-NO SR</span>
            &nbsp; {opNoSrPercent}%
          </div>
        </div>
      </div>
      <div className="action"></div>
    </div>
  )
}

export default SummaryMetricCard
