import React, { useState } from 'react'
import AppContext from './AppContext'

interface AppContextProviderProps {
    children: React.ReactNode
    commandData?: Array<Record<string, any>>
    vehicleData?: Array<Record<string, any>>
    vehiclePlatforms?: string[]
    selectedCommandLevel: string
}

export const AppContextProvider = ({ children, commandData: initialCommandData, vehicleData: initialVehicleData, vehiclePlatforms: initialVehiclePlatforms, selectedCommandLevel: initialCommandLevel }: AppContextProviderProps) => {
    const [commandData] = useState(initialCommandData || [])
    const [vehicleData] = useState(initialVehicleData || [])
    const [platformTypes] = useState(initialVehiclePlatforms || [])
    const [selectedCommandLevel, setSelectedCommandLevel] = useState(initialCommandLevel)

    return (
        <AppContext.Provider value={{ commandData, vehicleData, platformTypes, selectedCommandLevel, setSelectedCommandLevel }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContext;