/************************************************************************************************
 * Dynamically generate vehicle data version 2.
 * Generates a full battalion of vehicles.
 * Revisions:
 * vehicleData5:
 *    - added maintenance history
 *    - added helper functions for maintenance history generation
 *    - synced status object with maintenance history (last maintenance date, isDeadlined, op. status, etc)
 *    - removed vehicle getter functions (aggregate fleet data, get vehicle by id, etc). Moved to dataUtil.ts
 * 
 * vehicleData4:
 *    - added command level aggregation
 ************************************************************************************************/



// ------------------------------------------------------------------------------------------------
// Vehicle Data Generation
// ------------------------------------------------------------------------------------------------

// Types for better structure
/**
 * FMC = Fully Mission Capable
 * - Vehicle is completely operational and can perform all its designated missions
 * - No major issues or repairs needed
 * 
 * PMC = Partially Mission Capable
 * - Vehicle can perform some but not all of its missions
 * - Has issues that limit its full capability but is still operational
 * 
 * NMCM = Non-Mission Capable Maintenance
 * - Vehicle cannot perform its mission due to maintenance requirements
 * - Waiting for repairs or scheduled maintenance
 *  Often called "deadlined" in maintenance terms
 * 
 * NMCS = Non-Mission Capable Supply
 * - Vehicle cannot perform its mission due to waiting for parts/supplies
 * - The maintenance team knows what's wrong but lacks necessary parts
 * - Also considered "deadlined" status
 */



// Maintenance History functions -------------------------------------

type ServiceType = 'Scheduled' | 'Corrective' | 'Emergency';
type MaintenanceCategory = 'Repair' | 'Annual Service' | 'Quarterly Service' | 'Monthly Service';

interface MaintenancePart {
  nsn: string;
  partName: string;
  quantity: number;
  unitPrice: number;
}

interface MaintenanceCheck {
  checkType: string;
  completed: boolean;
  measurement?: string;
  quantity?: number;
  remarks?: string;
}

interface Fault {
  faultNumber: string;
  description: string;
  status: 'Open' | 'In Progress' | 'Completed' | 'Deferred';
  priority: 'High' | 'Medium' | 'Low';
  systemAffected: string;
  eic: string;
  partsUsed: MaintenancePart[];
  laborHours: number;
  correctiveAction?: string;
}

interface QualityControl {
  inspectorId: string;
  inspectionDate: string;
  passed: boolean;
  remarks: string;
}

interface MaintenanceRecord {
  workOrderNumber: string;
  date: string;
  type: ServiceType;
  level: MaintenanceLevel;
  mileageAtService: number;
  hoursAtService: number;
  technicianId: string;
  supervisorId: string;
  faults: Fault[];
  pmcsPerformed: boolean;
  serviceType: {
    scheduled: boolean;
    category: MaintenanceCategory;
    deadlineStatus: boolean;
  };
  maintenanceChecks?: MaintenanceCheck[];
  qualityControl: QualityControl;
}

interface Modification {
  modNumber: string;
  date: string;
  description: string;
  authority: string;
  technicianId: string;
  verified: boolean;
}

interface RecurringService {
  serviceType: string;
  lastPerformed: string;
  nextDue: string;
  interval: string;
}

interface MaintenanceHistory {
  mileage: number;
  engineHours: number;
  records: MaintenanceRecord[];
  modifications: Modification[];
  recurringServices: RecurringService[];
}


// Common maintenance data
const MAINTENANCE_DATA = {
  systems: {
    Tank: [
      'Main Gun', 'Engine', 'Transmission', 'Track Assembly', 'Fire Control', 
      'Turret Drive', 'Hull Electronics', 'NBC System', 'Hydraulics'
    ],
    IFV: [
      'Engine', 'Transmission', 'Track Assembly', 'TOW System', 'Turret Electronics',
      '25mm Chain Gun', 'Hull Electronics', 'NBC System', 'Hydraulics'
    ],
    'Command Vehicle': [
      'Engine', 'Communications Suite', 'Power Distribution', 'Environmental Control',
      'Mission Command Systems', 'Antenna Systems'
    ],
    'Support Vehicle': [
      'Engine', 'Transmission', 'Brake System', 'Electrical System',
      'Suspension', 'Climate Control'
    ],
    'Recovery Vehicle': [
      'Engine', 'Winch System', 'Crane Assembly', 'Hydraulics', 'Track Assembly',
      'Recovery Equipment', 'Tool Systems'
    ]
  },
  commonFaults: {
    Tank: [
      'Track tension regulator failure',
      'Main gun stabilization issue',
      'Engine oil pressure low',
      'Turret drive malfunction',
      'Fire control computer error'
    ],
    IFV: [
      'TOW launcher malfunction',
      '25mm feed system jam',
      'Track pad wear excessive',
      'Power distribution fault',
      'Hydraulic pressure loss'
    ],
    'Command Vehicle': [
      'Communications system error',
      'Generator malfunction',
      'AC unit failure',
      'Network system fault',
      'Antenna alignment issue'
    ]
  },
  parts: {
    Tank: [
      { nsn: '2530-01-603-2462', name: 'Track Tension Regulator', price: 1200 },
      { nsn: '1015-01-505-0635', name: 'Gun Mount Assembly', price: 2500 },
      { nsn: '2815-01-495-1875', name: 'Engine Filter Kit', price: 350 }
    ],
    IFV: [
      { nsn: '1440-01-487-2797', name: 'TOW Launch Motor', price: 1800 },
      { nsn: '1005-01-445-6098', name: '25mm Barrel', price: 3200 },
      { nsn: '2530-01-603-2462', name: 'Track Assembly', price: 1500 }
    ]
  }
};

// Helper functions for maintenance history generation
const deriveOperationalStatus = (maintenanceHistory: MaintenanceHistory): {
  operationalStatus: OperationalStatus;
  isDeadlined: boolean;
} => {
  // Check most recent maintenance record
  // const latestRecord = maintenanceHistory.records[0];
  
  // Check for open faults across all recent records (last 30 days)
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  
  const recentRecords = maintenanceHistory.records.filter(record => 
    new Date(record.date) >= thirtyDaysAgo
  );

  const openFaults = recentRecords.flatMap(record => 
    record.faults.filter(fault => 
      fault.status === 'Open' || fault.status === 'In Progress'
    )
  );

  // Check for parts on order
  const waitingForParts = openFaults.some(fault => 
    fault.partsUsed.some(part => part.quantity === 0 || !part.quantity)
  );

  // Logic for status determination
  if (openFaults.length === 0) {
    return { operationalStatus: 'FMC', isDeadlined: false };
  } else if (waitingForParts) {
    return { operationalStatus: 'NMCS', isDeadlined: true };
  } else if (openFaults.some(fault => fault.priority === 'High')) {
    return { operationalStatus: 'NMCM', isDeadlined: true };
  } else {
    return { operationalStatus: 'PMC', isDeadlined: false };
  }
};

const determineMaintenanceLevel = (maintenanceHistory: MaintenanceHistory): MaintenanceLevel => {
  const latestRecord = maintenanceHistory.records[0];
  if (!latestRecord) return '10/20';
  
  // If there's an open high priority fault, escalate to higher maintenance level
  const hasHighPriorityFault = latestRecord.faults.some(
    fault => fault.priority === 'High' && 
    (fault.status === 'Open' || fault.status === 'In Progress')
  );

  if (hasHighPriorityFault) {
    return '30';
  }

  return latestRecord.level;
};

const generateMileage = (platformCategory: PlatformCategory): number => {
  const baseRange = {
    'Tank': { min: 1000, max: 5000 },
    'IFV': { min: 1500, max: 6000 },
    'Command Vehicle': { min: 800, max: 3000 },
    'Support Vehicle': { min: 1200, max: 4000 },
    'Recovery Vehicle': { min: 500, max: 2500 }
  }[platformCategory] || { min: 1000, max: 4000 };

  return Math.floor(Math.random() * (baseRange.max - baseRange.min) + baseRange.min);
};

const generateEngineHours = (mileage: number): number => {
  return Math.floor(mileage / 10 * (0.8 + Math.random() * 0.4));
};

const generateFault = (platformCategory: PlatformCategory, date: string, number: number): Fault => {
  //@ts-ignore
  const faults = MAINTENANCE_DATA.commonFaults[platformCategory] || MAINTENANCE_DATA.commonFaults.Tank;
  //@ts-ignore
  const systems = MAINTENANCE_DATA.systems[platformCategory] || MAINTENANCE_DATA.systems.Tank;
  //@ts-ignore
  const parts = MAINTENANCE_DATA.parts[platformCategory] || MAINTENANCE_DATA.parts.Tank;
  
  const fault = faults[Math.floor(Math.random() * faults.length)];
  const system = systems[Math.floor(Math.random() * systems.length)];
  const part = parts[Math.floor(Math.random() * parts.length)];

  return {
    faultNumber: `F-${date.split('-')[0]}-${number}`,
    description: fault,
    status: 'Completed',
    priority: Math.random() > 0.7 ? 'High' : Math.random() > 0.5 ? 'Medium' : 'Low',
    systemAffected: system,
    eic: Math.floor(Math.random() * 999).toString().padStart(3, '0'),
    partsUsed: [
      {
        nsn: part.nsn,
        partName: part.name,
        quantity: 1,
        unitPrice: part.price
      }
    ],
    laborHours: Math.floor(Math.random() * 6) + 2,
    correctiveAction: `Replaced ${part.name.toLowerCase()} and verified operation`
  };
};

const generateMaintenanceHistory = (platformCategory: PlatformCategory): MaintenanceHistory => {
  const mileage = generateMileage(platformCategory);
  const engineHours = generateEngineHours(mileage);
  const records: MaintenanceRecord[] = [];
  
  // Generate 3-6 maintenance records over the past year
  const numRecords = Math.floor(Math.random() * 4) + 3;
  const today = new Date();
  
  // Generate records with potential open faults
  for (let i = 0; i < numRecords; i++) {
    const recordDate = new Date(today);
    recordDate.setDate(today.getDate() - Math.floor(Math.random() * 365));
    
    const isRecent = (today.getTime() - recordDate.getTime()) < (30 * 24 * 60 * 60 * 1000);
    const faults: Fault[] = [];
    
    // For recent records, maybe generate an open fault
    if (isRecent && Math.random() > 0.7) {
      const fault = generateFault(platformCategory, formatDate(recordDate), i + 1);
      fault.status = Math.random() > 0.5 ? 'Open' : 'In Progress';
      fault.priority = Math.random() > 0.7 ? 'High' : 'Medium';
      faults.push(fault);
    }
    
    const record: MaintenanceRecord = {
      workOrderNumber: `WO-${recordDate.getFullYear()}-${i + 1}`,
      date: formatDate(recordDate),
      type: faults.length > 0 ? 'Corrective' : 'Scheduled',
      level: faults.length > 0 && faults[0].priority === 'High' ? '30' : '10/20',
      mileageAtService: mileage - (i * Math.floor(Math.random() * 500)),
      hoursAtService: engineHours - (i * Math.floor(Math.random() * 50)),
      technicianId: `${['SPC', 'SGT', 'SSG'][Math.floor(Math.random() * 3)]}-${Math.floor(Math.random() * 999999)}`,
      supervisorId: `${['SSG', 'SFC'][Math.floor(Math.random() * 2)]}-${Math.floor(Math.random() * 999999)}`,
      faults: faults,
      pmcsPerformed: true,
      serviceType: {
        scheduled: faults.length === 0,
        category: faults.length === 0 ? 'Quarterly Service' : 'Repair',
        deadlineStatus: faults.length > 0 && faults[0].priority === 'High'
      },
      qualityControl: {
        inspectorId: `${['SSG', 'SFC'][Math.floor(Math.random() * 2)]}-${Math.floor(Math.random() * 999999)}`,
        inspectionDate: formatDate(recordDate),
        passed: faults.length === 0,
        remarks: faults.length === 0 ? 
          'All maintenance performed to standard' : 
          'Maintenance ongoing - awaiting completion of repairs'
      }
    };
    
    records.push(record);
  }

  // Sort records by date, most recent first
  records.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const maintenanceHistory: MaintenanceHistory = {
    mileage,
    engineHours,
    records,
    modifications: [],
    recurringServices: [
      {
        serviceType: 'Quarterly Service',
        lastPerformed: records[0].date,
        nextDue: formatDate(new Date(new Date(records[0].date).getTime() + 90 * 24 * 60 * 60 * 1000)),
        interval: '90 days'
      },
      {
        serviceType: 'Annual Service',
        lastPerformed: records[0].date,
        nextDue: formatDate(new Date(new Date(records[0].date).getTime() + 365 * 24 * 60 * 60 * 1000)),
        interval: '365 days'
      }
    ]
  };

  return maintenanceHistory;
};


// -----------------------------




type OperationalStatus = 'FMC' | 'PMC' | 'NMCM' | 'NMCS';
type MaintenanceLevel = '10/20' | '30' | '40' | '50';
type PlatformCategory = 'Tank' | 'IFV' | 'Command Vehicle' | 'Support Vehicle' | 'Recovery Vehicle';

interface VehicleStatus {
  isDeadlined: boolean;
  operationalStatus: OperationalStatus;
  maintenanceLevel: MaintenanceLevel;
  lastMaintenanceDate: string;
  nextScheduledMaintenance: string;
  fuelLevel: number;
}

interface UnitAssignment {
  platoon: string;
  company: string;
  battalion: string;
  brigade: string;
  division: string;
}

export interface Vehicle {
  id: string;
  bumperNumber: string;
  registrationNumber: string;
  commandId: string;
  unitAssignment: UnitAssignment;
  name: string;
  platform: string;
  platformCategory: PlatformCategory;
  platformType: string;
  status: VehicleStatus;
  maintenanceHistory: MaintenanceHistory;
}

// Configuration for unit structure
const UNIT_CONFIG = {
  companies: {
    A: { type: 'Tank', platformType: 'M1A2 SEPv3', category: 'Tank' },
    B: { type: 'Mechanized', platformType: 'M2A3 Bradley', category: 'IFV' },
    C: { type: 'Tank', platformType: 'M1A2 SEPv3', category: 'Tank' },
    D: { type: 'Mechanized', platformType: 'M2A3 Bradley', category: 'IFV' },
    HHC: { type: 'Headquarters', platformType: 'Mixed', category: 'Support Vehicle' }
  },
  platoonsPerCompany: 3,
  vehiclesPerPlatoon: 4,
  namesPrefix: [
    'Iron', 'Steel', 'Thunder', 'Lightning', 'Desert', 'Night', 'Storm', 'Battle',
    'War', 'Combat', 'Dragon', 'Tiger', 'Eagle', 'Shadow', 'Ghost'
  ],
  namesSuffix: [
    'Hunter', 'Striker', 'Warrior', 'Knight', 'Blade', 'Hammer', 'Shield', 'Spear',
    'Sword', 'Charger', 'Runner', 'Stalker', 'Ranger', 'Raider', 'Guardian'
  ]
};

// Define a type for the company keys
type CompanyKeys = keyof typeof UNIT_CONFIG.companies;

// Helper functions
const generateVehicleId = (company: string, number: number) => 
  `1-${company}-${number.toString().padStart(3, '0')}`;

const generateBumperNumber = (company: string, number: number) => 
  `${company}-${number.toString().padStart(3, '0')}`;

const generateRegistration = (number: number) => 
  `USA${number.toString().padStart(6, '0')}`;

const generateVehicleName = () => {
  const prefix = UNIT_CONFIG.namesPrefix[Math.floor(Math.random() * UNIT_CONFIG.namesPrefix.length)];
  const suffix = UNIT_CONFIG.namesSuffix[Math.floor(Math.random() * UNIT_CONFIG.namesSuffix.length)];
  return `${prefix} ${suffix}`;
};

const formatDate = (date: Date): string => {
  return date.toISOString().split('T')[0];
};

const generateStatus = (maintenanceHistory: MaintenanceHistory): VehicleStatus => {
  const { operationalStatus, isDeadlined } = deriveOperationalStatus(maintenanceHistory);
  const maintenanceLevel = determineMaintenanceLevel(maintenanceHistory);
  
  // Get dates from maintenance history
  const lastRecord = maintenanceHistory.records[0];
  const lastMaintenanceDate = lastRecord ? lastRecord.date : formatDate(new Date());
  
  // Calculate next maintenance based on recurring services
  const nextService = maintenanceHistory.recurringServices
    .reduce((earliest, service) => {
      return new Date(service.nextDue) < new Date(earliest) ? 
        service.nextDue : earliest;
    }, maintenanceHistory.recurringServices[0].nextDue);

  return {
    isDeadlined,
    operationalStatus,
    maintenanceLevel,
    lastMaintenanceDate,
    nextScheduledMaintenance: nextService,
    fuelLevel: Math.floor(Math.random() * 40) + 60 // 60-100%
  };
};

const generateHQVehicles = (company: string, startIndex: number): Vehicle[] => {
  const vehicles: Vehicle[] = [];
  const platforms = {
    'HHC': [
      { type: 'M1068 Command Post Carrier', category: 'Command Vehicle' },
      { type: 'M88A2 Recovery Vehicle', category: 'Recovery Vehicle' },
      { type: 'M577 Command Post Carrier', category: 'Command Vehicle' },
      { type: 'M113 Medical Evacuation Vehicle', category: 'Support Vehicle' }
    ],
    'default': [
      { type: UNIT_CONFIG.companies[company as CompanyKeys].platformType, category: UNIT_CONFIG.companies[company as CompanyKeys].category }
    ]
  };

  const platformList = company === 'HHC' ? platforms.HHC : platforms.default;

  
  platformList.forEach((platform, idx) => {
    const maintenanceHistory = generateMaintenanceHistory(platform.category as PlatformCategory);
    const status = generateStatus(maintenanceHistory);

    vehicles.push({
      id: generateVehicleId(company, startIndex + idx),
      bumperNumber: generateBumperNumber(company, startIndex + idx),
      registrationNumber: generateRegistration(startIndex + idx),
      commandId: `HQ-${company}-1-67AR`,
      unitAssignment: {
        platoon: "Headquarters Platoon",
        company: company,
        battalion: "1-67",
        brigade: "2nd BCT",
        division: "1st Armored"
      },
      name: generateVehicleName(),
      platform: platform.type,
      platformCategory: platform.category as PlatformCategory,
      platformType: company === 'HHC' ? 'Support Vehicle' : 'Combat Vehicle',
      status: status,
      maintenanceHistory: maintenanceHistory
    });
  });

  return vehicles;
};

const generateCompanyVehicles = (company: string, startIndex: number): Vehicle[] => {
  const vehicles: Vehicle[] = [];
  
  // Generate HQ vehicles first
  vehicles.push(...generateHQVehicles(company, startIndex));
  
  // Generate platoon vehicles
  for (let platoon = 1; platoon <= UNIT_CONFIG.platoonsPerCompany; platoon++) {
    const platoonName = `${platoon}${platoon === 1 ? 'st' : platoon === 2 ? 'nd' : 'rd'} Platoon`;
    
    for (let vic = 1; vic <= UNIT_CONFIG.vehiclesPerPlatoon; vic++) {
      const vehicleNum = (platoon * 100) + vic;
      const platformCategory = UNIT_CONFIG.companies[company as CompanyKeys].category as PlatformCategory;
      const maintenanceHistory = generateMaintenanceHistory(platformCategory);
      const status = generateStatus(maintenanceHistory);
      
      vehicles.push({
        id: generateVehicleId(company, vehicleNum),
        bumperNumber: generateBumperNumber(company, vehicleNum),
        registrationNumber: generateRegistration(startIndex + vehicleNum),
        commandId: `${platoon}PLT-${company}-1-67AR`,
        unitAssignment: {
          platoon: platoonName,
          company: company,
          battalion: "1-67",
          brigade: "2nd BCT",
          division: "1st Armored"
        },
        name: generateVehicleName(),
        platform: UNIT_CONFIG.companies[company as CompanyKeys].platformType,
        platformCategory: platformCategory,
        platformType: 'Combat Vehicle',
        status: status,
        maintenanceHistory: maintenanceHistory
      });
    }
  }
  
  return vehicles;
};

export const generateBattalionVehicles = (): Vehicle[] => {
  let vehicles: Vehicle[] = [];
  let startIndex = 1;
  
  // Generate vehicles for each company
  Object.keys(UNIT_CONFIG.companies).forEach(company => {
    const companyVehicles = generateCompanyVehicles(company, startIndex);
    vehicles = [...vehicles, ...companyVehicles];
    startIndex += 500; // Leave room between companies for expansion
  });
  
  return vehicles;
};


export const fullBattalionVehicles = generateBattalionVehicles();