import "./Navbar.css"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const Navbar = () => {
  const navigate = useNavigate()
  const [dropdownVisible, setDropdownVisible] = useState(false)

  return (
    <nav>
      <div className="nav-container">
        <div className="left-buttons">
          <button className="button-2" onClick={() => setDropdownVisible(!dropdownVisible)}>
            <i className="icon-reorder nav-icon"></i> 
            &nbsp; <span className="nav-text">COMMAND VIEW</span>
          </button>
          {dropdownVisible && (
            <div className="dropdown" onMouseOut={() => setDropdownVisible(!dropdownVisible)}>
              <button onClick={() => navigate('/command-view')}>COMMAND VIEW</button>
              <button onClick={() => navigate('/maintainer-view')}>MAINTAINER VIEW</button>
            </div>
          )}
          <button className="breadcrumb" onClick={() => navigate('/fleet/readiness')}>
            <i className="icon-home nav-icon large-icon"></i>
            &nbsp; <span className="nav-text">READINESS OVERVIEW</span>
          </button>
        </div>

        <div className="right-buttons">
          <button className="button-2">
            <i className="icon-comment-alt nav-icon"></i> 
            &nbsp; <span className="nav-text">FEEDBACK</span>
          </button>
          <button className="button-2" onClick={() => navigate('/info')}>
            <i className="icon-info-sign nav-icon"></i>
            &nbsp; <span className="nav-text">INFO</span>
          </button>
          <button className="button-2">
            <i className="icon-file-text nav-icon"></i>
            &nbsp; <span className="nav-text">EXPORT</span>
          </button>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
