import { useState } from 'react'
import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import "./Fleet.css"
import AppContext from '../context/AppContext'
import Navbar from "../components/Navbar"
import SideFilterPanel from "../components/Filters/SideFilterPanel"
import MetricPanel from "../components/MetricPanel"
import TopFilterPanel from "../components/Filters/TopFilterPanel"
import ReadinessView from "../components/UnitViews/ReadinessView"
import ActiveFaultsView from "../components/UnitViews/ActiveFaultsView"
import UnitViewNavbar from "../components/UnitViews/UnitViewNavbar"
import VehicleListView from "../components/UnitViews/VehicleListView"
import { ReadinessData } from "../components/UnitViews/ReadinessView"
import { CommandLevel, aggregateFleetData } from '../data/dataUtil'
import { getChildCommandLevel } from '../data/dataUtil'



interface FleetProps {
  defaultTab?: string;
}

function Fleet({ defaultTab = 'Readiness' }: FleetProps) {
  const { tab } = useParams()
  const { selectedCommandLevel, setSelectedCommandLevel } = useContext(AppContext)
  const { vehicleData }: { vehicleData: any } = useContext(AppContext)
  // @ts-ignore: Ignore unused variable warning
  const [aggregateData, setAggregateData] = useState<[]>([])
  const [readinessData, setReadinessData] = useState<ReadinessData[]>()



  useEffect(() => {
    const newAggregateData: any = aggregateFleetData(vehicleData, selectedCommandLevel.toLowerCase() as CommandLevel)
    setAggregateData(newAggregateData)

    // add the command level to the readiness data
    const readinessDataWithCommandLevel = newAggregateData[0].summary.map((data: ReadinessData) => ({
      ...data,
      commandLevel: getChildCommandLevel(selectedCommandLevel.toLowerCase() as CommandLevel)
    }))
    setReadinessData(readinessDataWithCommandLevel as unknown as ReadinessData[])

    console.log('vehicleData: ', vehicleData)
    console.log('aggregateData: ', newAggregateData)
    console.log('readinessDataWithCommandLevel: ', readinessDataWithCommandLevel)
  }, [selectedCommandLevel])

  
  
  const tabMapping: { [key: string]: string } = {
    'readiness': 'Readiness',
    'vehicle-list': 'Vehicle List',
    'active-faults': 'Active Faults',
    'potential-warnings': 'Potential Warnings',
    'service-request-history': 'Service Request History',
    'total-service-request-history': 'Total Service Request History',
    '10-active-faults': '10 Active Faults'
  }
  const activeTab = tabMapping[tab?.toLowerCase() ?? ''] || tab || defaultTab


  return (
    <div className="fleet-page">
      <Navbar />
      <TopFilterPanel selectedCommandLevel={selectedCommandLevel} setSelectedCommandLevel={setSelectedCommandLevel} />

      <div className="data-container">
        <div className="side-view">
          <div className="filter-by-label">FILTER BY:</div>
          <SideFilterPanel />
        </div>

        <div>
          <div className="unit-view-panel">
            <UnitViewNavbar defaultTab={defaultTab} />
          </div>
          <div className="main-view">
            
            <MetricPanel />
            <br />

          
            <div>
              {activeTab === "Readiness" && readinessData && <ReadinessView readinessData={readinessData} selectedCommandLevel={selectedCommandLevel} setSelectedCommandLevel={setSelectedCommandLevel} />}
              {activeTab === "Vehicle List" && <VehicleListView selectedCommandLevel={selectedCommandLevel} setSelectedCommandLevel={setSelectedCommandLevel} />}
              {activeTab === "Active Faults" && <ActiveFaultsView />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Fleet
