import './ButtonFilterPanel.css'

interface ButtonFilterPanelProps {
  title: string
  buttons: { label: string; active: boolean }[]
}

const ButtonFilterPanel = ({ title, buttons }: ButtonFilterPanelProps) => {
  return (
    <div className="button-filter-panel">
      <span className="title">{title}</span>
      <div className="button-container">
        {buttons.map((button, index) => (
          <button
            key={index}
            className={button.active ? 'active' : ''}
          >
            {button.label}
          </button>
        ))}
      </div>
    </div>
  )
}

export default ButtonFilterPanel
