import './CheckboxFilterPanel.css'

interface CheckboxFilterPanelProps {
  title: string
  checkboxes: string[]
}

const CheckboxFilterPanel = ({ title, checkboxes }: CheckboxFilterPanelProps) => {

  return (
    <div className="checkbox-filter-panel" style={{ textAlign: 'left' }}>
      <span className="title">{title}</span>
      <div className="checkbox-container">
        {checkboxes.map((checkbox, index) => (
          <label key={index}>
            <input type="checkbox" /> <span className="checkbox-label">{checkbox}</span><br/>
          </label>
        ))}
      </div>
    </div>
  )
  
}

export default CheckboxFilterPanel
