import "./VehicleOverviewCard.css"


const VehicleOverviewCard = ({ overdueCount, activeFaultsCount, warningsCount }: 
  { overdueCount: number; activeFaultsCount: number;  warningsCount: number; }) => {

  // convert to format #,###
  const overdueCountStr = overdueCount.toLocaleString()
  const activeFaultsCounStr = activeFaultsCount.toLocaleString()
  const warningsCountStr = warningsCount.toLocaleString()
  
  return (
    <div className="vehicle-overview-card">
      <div className="content">
        <div>
          <div className="row">
            <span className="label">Vehicles Overdue</span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span className="value">{overdueCountStr}</span>
          </div>
          <br/>
          <div className="row">
            <span className="label">Active Faults</span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span className="value">{activeFaultsCounStr}</span>
          </div>
          <br/>
          <div className="row">
            <span className="label">Warnings</span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span className="value">{warningsCountStr}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VehicleOverviewCard
