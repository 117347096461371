import './VehicleView.css'
import { Vehicle } from '../../data/vehicleData5'


const VehicleView = ({ vehicle }: { vehicle: Vehicle }) => {

  console.log(vehicle)

  return (
    <div className='vehicle-view'>
    </div>
  )
}

export default VehicleView