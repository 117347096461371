import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AppContext from '../../context/AppContext'

import './VehicleListView.css'
import React from 'react'
import DataTable from 'react-data-table-component'
import PlatformImage from './PlatformImage'

import { TransformedVehicle as VehicleData, vehicleList } from '../../data/dataUtil.ts'



interface VehicleListViewProps {
  selectedCommandLevel: string;
  setSelectedCommandLevel: (commandLevel: string) => void;
}


const VehicleListView: React.FC<VehicleListViewProps> = ({ selectedCommandLevel }) => {
  const navigate = useNavigate()
  const { vehicleData }: { vehicleData: any } = useContext(AppContext)
  // @ts-ignore: Ignore unused variable warning
  const [aggregateData, setAggregateData] = useState<[]>([])
  const [data, setData] = useState<VehicleData[]>([])


  const handleImageClick = (id: string) => {
    navigate(`/vehicle/detail/${id}`)
  }


  const columns = [
    {
      name: 'PLATFORM',
      selector: (row: VehicleData) => row.id,
      cell: (row: VehicleData) => (
        <div onClick={() => handleImageClick(row.id)} className='clickable'>
          <PlatformImage platform={row.platform} />
        </div>
        // // <div className='platform'><img src={`/images/platforms/${row.platform}.png`} alt={row.platform} /></div>
        // <div className='platform'>
        //   <img src={`/images/${row.platform.replace(/ /g, '-')}.png`} alt={row.platform} width='100px' />
        //   <div className='platform-name'>{row.platform}</div>
        // </div>
      ),
      sortable: true,
      width: '190px',
    },
    {
      name: 'BUMPER #',
      selector: (row: VehicleData) => row.bumperNumber,
      cell: (row: VehicleData) => (
        <div onClick={() => handleImageClick(row.id)} className='clickable'>
          {row.bumperNumber}
        </div>
      ),
      sortable: true,
      width: '130px',
    },
    {
      name: 'REG #',
      selector: (row: VehicleData) => row.registrationNumber,
      cell: (row: VehicleData) => (
        <div onClick={() => handleImageClick(row.id)} className='clickable'>
          {row.registrationNumber}
        </div>
      ),
      sortable: true,
      width: 'auto',
    },
    {
      name: 'STAUS',
      selector: (row: VehicleData) => row.operationalStatus,
      cell: (row: VehicleData) => (
        <div onClick={() => handleImageClick(row.id)} className='clickable'>
          {row.operationalStatus}
        </div>
      ),
      sortable: true,
      width: '100px',
    },
    {
      name: (
        <>
        DEADLINED<br/>(NMCM / NMCS)
        </>
      ),
      selector: (row: VehicleData) => row.deadlinedStatus,
      cell: (row: VehicleData) => (
        <div onClick={() => handleImageClick(row.id)} className='clickable'>
          {row.operationalStatus}
        </div>
      ),
      sortable: true,
      width: '150px',
    },
    {
      name: (
        <>
          MAINTENANCE<br />LEVEL
        </>
      ),
      selector: (row: VehicleData) => row.maintenanceLevel,
      cell: (row: VehicleData) => (
        <div onClick={() => handleImageClick(row.id)} className='clickable'>
          {row.maintenanceLevel}
        </div>
      ),
      sortable: true,
      width: 'auto',
    },
    {
      name: (
        <>
          LAST<br />MAINTENANCE
        </>
      ),
      selector: (row: VehicleData) => row.lastMaintenanceDate,
      cell: (row: VehicleData) => (
        <div onClick={() => handleImageClick(row.id)} className='clickable'>
          {row.lastMaintenanceDate}
        </div>
      ),
      sortable: true,
      width: 'auto',
    },
    {
      name: 'ACTIONS',
      selector: (row: VehicleData) => row.actions,
      cell: (row: VehicleData) => (
        <div className="ellipsis-cell">
          <i className="icon-ellipsis-horizontal nav-icon large-icon"></i> {row.actions}
        </div>
      ),
      sortable: false,
      width: 'auto',
    },
  ]



  useEffect(() => {
    // const newAggregateData: any = aggregateFleetData(vehicleData, selectedCommandLevel.toLowerCase() as CommandLevel)
    // setAggregateData(newAggregateData)
    setData(vehicleList(vehicleData) as unknown as VehicleData[])

    // console.log('vehicleData: ', vehicleData)
    // console.log('aggregateData: ', newAggregateData)
    console.log('vehicleList: ', vehicleList(vehicleData))

  }, [selectedCommandLevel])
  

  return (
    <div className="vehicle-list-view">

      <DataTable
        columns={columns}
        data={data}
        pagination
        responsive
        className='readiness-data-table'
      />
    </div>
  )
}

export default VehicleListView