import { useContext } from 'react'
import AppContext from '../../context/AppContext'
import ButtonFilterPanel from './ButtonFilterPanel'
import CheckboxFilterPanel from './CheckboxFilterPanel'

const SideFilterPanel = () => {
  //@ts-ignore
  const { platformTypes } = useContext(AppContext)

  return (
    <div>
      <ButtonFilterPanel 
        title="Platform" 
        buttons={[
          { label: 'M1068', active: false },
          { label: 'M113', active: false },
          { label: 'M1A2', active: false },
          { label: 'M577', active: false },
          { label: 'M88A2', active: false },
        ]} 
      />

      {/* <br />
      
      <ButtonFilterPanel 
        title="CBM vs Non-CBM" 
        buttons={[
          { label: 'All', active: true },
          { label: 'CBM+ Enabled', active: false },
          { label: 'Non-BM+ Enabled', active: false }
        ]} 
      /> */}

      <br/>

      <div style={{ display: 'flex', gap: '10px' }}>
        <div style={{ flex: '1 1 50%' }}>
          <CheckboxFilterPanel 
            title="Subsystem" 
            checkboxes={["Brakes", "Driveline", "Electrical", "Engine", "Transmission"]} 
          />
        </div>
        <div style={{ flex: '1 1 50%' }}>
          <CheckboxFilterPanel 
            title="Operation Status" 
            checkboxes={["Deadlined", "FMC", "PMC", "NMCM", "NMCS"]} 
          />
        </div>
      </div>

      <br />
      {/* <div className="panel">
        <div className="title">Readiness</div>
        <DeadlinedBarChart />
      </div> */}
    </div>
  )
}

export default SideFilterPanel
