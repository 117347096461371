import './VehicleDetail.css'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AppContext from '../context/AppContext'
import Navbar from '../components/Navbar'
import TopFilterPanel from '../components/Filters/TopFilterPanel'
import VehicleView from '../components/UnitViews/VehicleView'
import PlatformImage from '../components/UnitViews/PlatformImage'
import VehicleDetailViewNavbar from '../components/VehicleDetailViews/VehicleDetailViewNavbar'

import { fullBattalionVehicles, Vehicle as VehicleInterface } from '../data/vehicleData5'
import { getVehicleById } from '../data/dataUtil'




const VehicleDetail = () => {
  const { id } = useParams()
  const { selectedCommandLevel, setSelectedCommandLevel } = useContext(AppContext)
  const [vehicle, setVehicle] = useState<VehicleInterface | null>(null)


  const handleBackClick = () => {
    window.history.back()
  }

  useEffect(() => {
    setVehicle(getVehicleById(fullBattalionVehicles, id ?? ''))
    console.log(getVehicleById(fullBattalionVehicles, id ?? ''))
  }, [id])

  return (
    <div className='vehicle-page'>
      <Navbar />
      <TopFilterPanel selectedCommandLevel={selectedCommandLevel} setSelectedCommandLevel={setSelectedCommandLevel} />

      <div className="data-container">

        <div className='side-view'>
          <span className='go-back brand-yellow' onClick={handleBackClick}>
            <i className="icon-chevron-left"></i>
            &nbsp;&nbsp; 
            GO BACK
          </span>
          <p className='vehicle-details-label'>VEHICLE DETAILS</p>

          <div className='platform-image'>
            <PlatformImage platform={vehicle?.platform ?? ''} width={160} />        
          </div>

          <div className='vehicle-title-panel'>
            <span className='label-1'>BUMPER # {vehicle?.bumperNumber}</span>
            <br/>
            <span className='label-2'>{vehicle?.commandId}</span>
          </div>
        </div>
        <div>
          <VehicleDetailViewNavbar defaultTab="Active Faults & Warnings" />
          {vehicle ? <VehicleView vehicle={vehicle} /> : <p></p>}
        </div>
        
      </div>
    </div>
  )
}

export default VehicleDetail