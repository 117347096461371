import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { AppContextProvider } from './context/AppContextProvider'
import Fleet from './pages/Fleet'
import Info from './pages/Info'
import VehicleDetail from './pages/VehicleDetail.tsx'

import { commandLevels }  from './data/commandData.ts'

// Vehicle Data is generated each time the app moun
import { fullBattalionVehicles } from './data/vehicleData5.ts'
import { getPlatforms } from './data/dataUtil.ts'

// TODO: Once the data is accurate, generate and save to staticVehicleData.ts and use staticVehicleData.ts
// import { vehicleData } from './data/staticVehicleData.ts'


function App() { 
  const vehiclePlatforms: string[] = getPlatforms(fullBattalionVehicles)
  const defaultCommandLevel = 'Battalion'

  const router = createBrowserRouter([
    { path: '/', element: <Fleet defaultTab="Readiness" /> },
    { path: '/fleet', element: <Fleet defaultTab="Readiness" /> },
    { path: '/fleet/:tab', element: <Fleet /> },
    { path: '/vehicle-list', element: <Fleet defaultTab="Vehicle List" /> },
    { path: '/vehicle/detail/:id', element: <VehicleDetail /> },
    { path: '/info', element: <Info /> },
  ]);

  return (
    <AppContextProvider 
      commandData={commandLevels} 
      vehicleData={fullBattalionVehicles} 
      vehiclePlatforms={vehiclePlatforms}
      selectedCommandLevel={defaultCommandLevel}
    >
      <RouterProvider router={router} />
    </AppContextProvider>
  );
}

export default App
