import React from "react"

const AppContext = React.createContext<{ 
  commandData: Array<Record<string, any>>, 
  platformTypes: string[],
  vehicleData: Array<Record<string, any>>,
  selectedCommandLevel: string; 
  setSelectedCommandLevel: (commandLevel: string) => void;
 }>
  (
    { commandData: [], vehicleData: [], platformTypes: [], selectedCommandLevel: '', setSelectedCommandLevel: () => {} }
  )

export default AppContext
